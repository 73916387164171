import "./NavMenu.css";

import { Link, NavLink } from "react-router-dom";
import React, { Component } from "react";

import { LoginMenu } from "./api-authorization/LoginMenu";
import logo from "../logo.svg";

export class NavMenu extends Component<{}, { collapsed: boolean }> {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    render() {
        return (
            <nav
                id="site-navigation"
                className="main-navigation"
                role="navigation"
            >
                <div className="container d-flex justify-content-between">
                    <div className="site-nav-left">
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                    </div>
                    <div className="site-nav-right">
                        <div className="menu-container">
                            <div>
                                <ul className="menu">
                                    <li className="menu-item">
                                        <NavLink
                                            to="/"
                                            exact
                                            activeClassName="current-menu-item"
                                        >
                                            Requests
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/quotes"
                                            exact
                                            activeClassName="current-menu-item"
                                        >
                                            Quotes
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/orders"
                                            exact
                                            activeClassName="current-menu-item"
                                        >
                                            Orders
                                        </NavLink>
                                    </li>
                                    <LoginMenu />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}
