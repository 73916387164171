import { GenericFieldHTMLAttributes, useField, useFormikContext } from "formik";
import React, { FunctionComponent, useEffect, useState } from "react";

import { Dealer } from "../../models";
import DealerTypeahead from "./DealerTypeahead";

const DealerTypeaheadField: FunctionComponent<GenericFieldHTMLAttributes> = ({
  name,
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  const [dealer, setDealer] = useState<Dealer[]>([]);

  const setValue = () => {
    if (dealer && dealer.length > 0) {
      setFieldValue(field.name, dealer[0].dealerId);
    }
  };

  useEffect(() => {
    setValue();
  }, [dealer]);

  return <DealerTypeahead selected={dealer} onChange={setDealer} />;
};

export default DealerTypeaheadField;
