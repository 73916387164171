import React, { Component } from "react";

import { ReactComponent as Check } from "bootstrap-icons/icons/check-circle-fill.svg";
import { Contact } from "../../models";
import { Link } from "react-router-dom";
import { ReactComponent as Paperclip } from "bootstrap-icons/icons/paperclip.svg";
import authService from "../api-authorization/AuthorizeService";
import axios from "axios";
import { saveAs } from "file-saver";

export class Contacts extends Component<
  {},
  { contacts: Contact[]; loading: boolean }
> {
  static displayName = Contacts.name;

  constructor(props) {
    super(props);
    this.downloadFile = this.downloadFile.bind(this);
    this.state = { contacts: [], loading: true };
  }

  async downloadFile(event: React.MouseEvent<HTMLElement>, contact: Contact) {
    event.preventDefault();
    const token = await authService.getAccessToken();

    axios
      .get(`api/contacts/${contact.contactId}/download`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      })
      .then(({ data }) => {
        saveAs(data, contact.fileName);
      });
  }

  componentDidMount() {
    this.populateContactData();
  }

  scrollToLocation() {
    const { hash } = window.location;
    if (hash.length > 0) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => element.scrollIntoView(), 0);
      }
    }
  }

  renderContacts(contacts: Contact[]) {
    return (
      <div>
        {contacts.length === 0 && <div>No requests found.</div>}
        <div>
          {contacts.map((contact) => (
            <div
              key={contact.contactId}
              id={contact.contactId.toString()}
              className="card"
              style={{ padding: "15px", marginBottom: "75px" }}
            >
              <h3>Request #{contact.contactId}</h3>
              <div className="pb-3">
                <em>{new Date(contact.createdOn).toLocaleDateString()}</em>
              </div>

              <div className="pb-3">
                <div className="display-linebreak">{contact.question}</div>
                {contact.fileName && (
                  <div>
                    <Paperclip />
                    <a href="#" onClick={(e) => this.downloadFile(e, contact)}>
                      {contact.fileName}
                    </a>
                  </div>
                )}
              </div>

              <hr />

              <div className="pt-3">
                <em> Your request was sent to the following dealers</em>
              </div>

              {contact.contactProductGroups.map((contactProductGroup) => (
                <div
                  key={contactProductGroup.contactProductGroupId}
                  className="pb-3"
                >
                  <div
                    className="font-weight-bold"
                    style={{ fontSize: "1.25rem", paddingBottom: "5px" }}
                  >
                    {contactProductGroup.productGroup.name}
                  </div>

                  {contactProductGroup.contactProductGroupDealers.length ===
                    0 && <div>No dealers contacted</div>}

                  {contactProductGroup.contactProductGroupDealers.length >
                    0 && (
                    <table
                      className="table table-striped"
                      aria-labelledby="tabelLabel"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Address</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Website</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactProductGroup.contactProductGroupDealers.map(
                          (contactProductGroupDealer) => (
                            <tr
                              key={
                                contactProductGroupDealer.contactProductGroupDealerId
                              }
                            >
                              <th>{contactProductGroupDealer.dealer.name}</th>
                              <td>
                                <div>
                                  {contactProductGroupDealer.dealer.street}{" "}
                                  {contactProductGroupDealer.dealer.number}
                                </div>
                                <div>
                                  {contactProductGroupDealer.dealer.zipCode}{" "}
                                  {contactProductGroupDealer.dealer.country}
                                </div>
                              </td>
                              <td>
                                <a
                                  href={`mailto:${contactProductGroupDealer.dealer.email}`}
                                >
                                  {contactProductGroupDealer.dealer.email}
                                </a>
                              </td>
                              <td>
                                {contactProductGroupDealer.dealer.telephone}
                              </td>
                              <td>
                                <a
                                  href={`http://${contactProductGroupDealer.dealer.url}`}
                                  target="_blank"
                                >
                                  {contactProductGroupDealer.dealer.url}
                                </a>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              ))}

              <div>
                {contact.orders.length === 0 && (
                  <Link
                    className="btn btn-primary"
                    to={`/orders/add/${contact.contactId}`}
                  >
                    Add Order
                  </Link>
                )}

                {contact.orders.length > 0 && (
                  <div className="alert alert-success">
                    <Check className="mr-2" />
                    <span>
                      Linked order{" "}
                      <em>
                        <Link to="/orders">{contact.orders[0].fileName}</Link>
                      </em>{" "}
                      (
                      {new Date(
                        contact.orders[0].createdOn
                      ).toLocaleDateString()}
                      )
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      this.renderContacts(this.state.contacts)
    );

    return (
      <div>
        <h2 id="tabelLabel">Requests</h2>
        {contents}
      </div>
    );
  }

  async populateContactData() {
    try {
      const token = await authService.getAccessToken();

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await axios.get<Contact[]>("api/contacts", config);
      this.setState({ contacts: response.data, loading: false }, () => {
        this.scrollToLocation();
      });
    } catch (ex) {
      console.log(ex);
      //to do: create api wrapper and check for 401, try to refresh tokens etc.
      window.location.href = "/authentication/logout";
    }
  }
}
