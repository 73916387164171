import React, { Component } from "react";

import AddOrder from "./components/orders/AddOrder";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import { Contacts } from "./components/contacts/Contacts";
import { Layout } from "./components/Layout";
import { Orders } from "./components/orders/Orders";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { Quotes } from "./components/quotes/Quotes";
import { Route } from "react-router";
import { TermsOfService } from "./components/TermsOfService";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <AuthorizeRoute exact path="/" component={Contacts} />
                <AuthorizeRoute exact path="/orders" component={Orders} />
                <AuthorizeRoute
                    exact
                    path="/orders/add/:contactId?"
                    component={AddOrder}
                />
                <AuthorizeRoute exact path="/quotes" component={Quotes} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms-of-service" component={TermsOfService} />
                <Route
                    path={ApplicationPaths.ApiAuthorizationPrefix}
                    component={ApiAuthorizationRoutes}
                />
            </Layout>
        );
    }
}
