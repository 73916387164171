import React, { Component } from "react";
import { saveAs } from "file-saver";
import authService from "../api-authorization/AuthorizeService";
import axios from "axios";
import { ReactComponent as Paperclip } from "bootstrap-icons/icons/paperclip.svg";
import { Quote } from "../../models";

export class Quotes extends Component<{}, { quotes: Quote[]; loading: boolean }> {
    static displayName = Quotes.name;

    constructor(props) {
        super(props);
        this.state = { quotes: [], loading: false };
    }

    async downloadFile(event: React.MouseEvent<HTMLElement>, quote: Quote) {
        event.preventDefault();
        const token = await authService.getAccessToken();

        axios
            .get(`api/quotes/${quote.quoteId}/download`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: "blob",
            })
            .then(({ data }) => {
                saveAs(data, quote.fileName);
            });
    }

    componentDidMount() {
        this.populateQuoteData();
    }

    renderQuotes(quotes: Quote[]) {
        return (
            <div
                className="card"
                style={{ padding: "15px", marginBottom: "75px" }}
            >


                <table className="table mt-3">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">File</th>
                            <th scope="col">Dealer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quotes.map((quote) => (
                            <tr key={quote.quoteId}>
                                <th scope="row">
                                    {new Date(
                                        quote.createdOn
                                    ).toLocaleDateString()}
                                </th>
                                <td>
                                    {quote.fileName && (
                                        <div>
                                            <Paperclip />
                                            <a
                                                href="#"
                                                onClick={(e) =>
                                                    this.downloadFile(e, quote)
                                                }
                                            >
                                                {quote.fileName}
                                            </a>
                                        </div>
                                    )}
                                </td>
                                <td>{quote.dealer.name}</td>
                              
                            </tr>
                        ))}
                    </tbody>
                </table>

                {quotes.length === 0 && (
                    <div className="mt-1">
                        No quotes uploaded. 
                    </div>
                )}
            </div>
        );
    }

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (
            this.renderQuotes(this.state.quotes)
        );

        return (
            <div>
                <h2>Quotes</h2>
                {contents}
            </div>
        );
    }

    async populateQuoteData() {
        try {
            const token = await authService.getAccessToken();

            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const response = await axios.get<Quote[]>("api/quotes", config);
            this.setState({ quotes: response.data, loading: false });
        } catch (ex) {
            console.log(ex);
            //to do: create api wrapper and check for 401, try to refresh tokens etc.
            window.location.href = "/authentication/logout";
        }
    }
}
