import React, { Component } from "react";

export class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <h2>Privacy policy</h2>
        <div></div>
      </div>
    );
  }
}
