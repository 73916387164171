import "./OrderUploadField.scss";

import { GenericFieldHTMLAttributes, useField, useFormikContext } from "formik";
import React, { FunctionComponent, useCallback, useState } from "react";

import { ReactComponent as Check } from "bootstrap-icons/icons/check.svg";
import authService from "../api-authorization/AuthorizeService";
import axios from "axios";
import { useDropzone } from "react-dropzone";

const OrderUploadField: FunctionComponent<GenericFieldHTMLAttributes> = ({
  name,
}) => {
  const permittedExtensions = ".pdf, .jpg, .png";
  const fileSizeLimit = 20971520;

  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  const [uploadError, setUploadError] = useState<string>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      if (!acceptedFiles.length) return;

      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append("file", file);

      setUploadError(null);
      setUploading(true);

      authService.getAccessToken().then((token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };

        axios
          .post<string>("api/orders/upload", formData, config)
          .then(({ data }) => {
            setUploading(false);
            setFieldValue(field.name, data);
          })
          .catch((error) => {
            setUploading(false);
            setUploadError(error.response.data.message[0]);
          });
      });
    },
    [field.name, setFieldValue]
  );

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: permittedExtensions,
      maxSize: fileSizeLimit,
    });

  return (
    <div
      {...getRootProps({
        className:
          (isDragActive ? "dropzone dropzone-dragover" : "dropzone") +
          (field.value ? " dropzone-success" : ""),
      })}
    >
      <input {...getInputProps()} />

      <div className="text-center">
        {!field.value && (
          <div>
            <button type="button" className="btn btn-primary">
              Choose a file
            </button>
            <div className="mt-2">Or drag and drop your file here</div>
            <div className="mt-4">
              Permitted extensions:{" "}
              <span className="font-weight-bold">{permittedExtensions}</span>
            </div>
            <div className="mt-2">
              Max filesize:{" "}
              <span className="font-weight-bold">
                {(fileSizeLimit / 1048576).toFixed(2)} MB
              </span>
            </div>
          </div>
        )}

        {field.value && (
          <div>
            <div>
              <Check className="icon-lg" /> {field.value}
            </div>
            <button type="button" className="btn btn-light btn-sm mt-2">
              Choose a different file
            </button>
          </div>
        )}

        {uploadError && (
          <div className="alert alert-danger" role="alert">
            {uploadError}
          </div>
        )}

        {fileRejections.length > 0 && (
          <div className="alert alert-danger" role="alert">
            Rejected files:
            <ul>
              {fileRejections.map((rejection) => (
                <li key={rejection.file.name}>
                  {rejection.file.name} -{" "}
                  {(rejection.file.size / 1048576).toFixed(2)} MB
                </li>
              ))}
            </ul>
          </div>
        )}

        {uploading && <div>uploading...</div>}
      </div>
    </div>
  );
};

export default OrderUploadField;
