import * as yup from "yup";

import { Contact, Dealer, Order } from "../../models";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import DealerTypeaheadField from "./DealerTypeaheadField";
import OrderUploadField from "./OrderUploadField";
import authService from "../api-authorization/AuthorizeService";
import axios from "axios";

const AddOrder: FunctionComponent = () => {
  const history = useHistory();
  const { contactId }: any = useParams();

  let order = new Order();
  order.dealerId = undefined;
  order.fileName = undefined;
  order.contactId = contactId;

  const [contacts, setContacts] = useState<Contact[]>([]);

  async function getContacts() {
    try {
      const token = await authService.getAccessToken();

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await axios.get<Contact[]>("api/contacts", config);

      setContacts(response.data);
    } catch (ex) {
      console.log(ex);
      //to do: create api wrapper and check for 401, try to refresh tokens etc.
      window.location.href = "/authentication/logout";
    }
  }

  useEffect(() => {
    getContacts();
  }, []);

  const validationSchema = yup.object({
    dealerId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(),
    fileName: yup.string().required(),
  });

  async function saveOrder(order: any) {
    const token = await authService.getAccessToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    await axios.post<Order>("api/orders", order, config);
    history.push("/orders");
  }

  return (
    <div>
      <h2>Add order</h2>
      {contacts && (
        <Formik
          initialValues={order}
          validationSchema={validationSchema}
          onSubmit={(values) => saveOrder(values)}
        >
          {(formProps: FormikProps<any>) => (
            <Form>
              <div className="form-group">
                <label>File</label>
                <OrderUploadField id="fileName" name="fileName" />
                <ErrorMessage
                  name="fileName"
                  component="div"
                  className="invalid-feedback d-block"
                />
              </div>

              <div className="form-group">
                <label htmlFor="dealerId">Dealer</label>
                <DealerTypeaheadField id="dealerId" name="dealerId" />
                <ErrorMessage
                  name="dealerId"
                  component="div"
                  className="invalid-feedback d-block"
                />
              </div>

              {contacts.length > 0 && !contactId && (
                <div className="form-group">
                  <label htmlFor="contactId">Request</label>
                  <Field
                    as="select"
                    id="contactId"
                    name="contactId"
                    className="form-control"
                    onChange={(e) => {
                      formProps.setFieldValue(
                        "contactId",
                        +e.currentTarget.value
                      );
                    }}
                  >
                    <option value={undefined}></option>
                    {contacts.map((contact) => (
                      <option key={contact.contactId} value={contact.contactId}>
                        {new Date(contact.createdOn).toLocaleDateString()} (
                        {contact.contactProductGroups[0].productGroup.name})
                      </option>
                    ))}
                  </Field>
                </div>
              )}

              <div className="form-group mt-4">
                <button
                  type="submit"
                  disabled={formProps.isSubmitting}
                  className="btn btn-primary"
                >
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AddOrder;
