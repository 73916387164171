import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Dealer } from "../../models";
import authService from "../api-authorization/AuthorizeService";
import axios from "axios";

const DealerTypeahead: FunctionComponent<IDealerTypeaheadProps> = (props) => {
  const typeahead = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = useCallback((query) => {
    setIsLoading(true);

    authService.getAccessToken().then((token) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get<Dealer[]>("/api/dealers?query=" + query, config)
        .then(({ data }) => {
          setOptions(data);
          setIsLoading(false);
        });
    });
  }, []);

  function handleKeyDown(e: any) {
    if (
      e.key === "Tab" &&
      typeahead.current.state &&
      typeahead.current.state.activeItem
    ) {
      props.onChange([typeahead.current.state.activeItem]);
    }

    //if (e.key === "Enter") {
    //    e.preventDefault();
    //}
  }

  return (
    <AsyncTypeahead
      {...props}
      ref={typeahead}
      id="dealer-typeahead"
      isLoading={isLoading}
      labelKey="name"
      minLength={3}
      onSearch={handleSearch}
      onKeyDown={handleKeyDown}
      options={options}
      placeholder="Search for a dealer..."
      renderMenuItemChildren={(option, props) => <div>{option.name}</div>}
    />
  );
};

export default DealerTypeahead;

export interface IDealerTypeaheadProps {
  selected: Dealer[];
  onChange: Dispatch<SetStateAction<Dealer[]>>;
}
