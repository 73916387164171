import React, { Component } from "react";

import { Link } from "react-router-dom";
import { Order } from "../../models";
import { ReactComponent as Paperclip } from "bootstrap-icons/icons/paperclip.svg";
import authService from "../api-authorization/AuthorizeService";
import axios from "axios";
import { saveAs } from "file-saver";

export class Orders extends Component<
    {},
    { orders: Order[]; loading: boolean }
> {
    static displayName = Orders.name;

    constructor(props) {
        super(props);
        this.downloadFile = this.downloadFile.bind(this);
        this.state = { orders: [], loading: true };
    }

    async downloadFile(event: React.MouseEvent<HTMLElement>, order: Order) {
        event.preventDefault();
        const token = await authService.getAccessToken();

        axios
            .get(`api/orders/${order.orderId}/download`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: "blob",
            })
            .then(({ data }) => {
                saveAs(data, order.fileName);
            });
    }

    async viewFile(event: React.MouseEvent<HTMLElement>, order: Order) {
        event.preventDefault();
        const token = await authService.getAccessToken();

        axios
            .get(`api/orders/${order.orderId}/download`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: "blob",
            })
            .then(({ data }) => {
                var file = new Blob([data], { type: "application/pdf" });
                var fileURL = window.URL.createObjectURL(file);
                var a = document.createElement("a");
                document.body.appendChild(a);
                //a.style = "display: none";
                a.href = fileURL;
                //a.download = order.fileName;
                a.target = "_blank";
                a.click();
                //let tab = window.open();
                //tab.location.href = fileURL;
            });
    }

    componentDidMount() {
        this.populateOrderData();
    }

    renderOrders(orders: Order[]) {
        return (
            <div
                className="card"
                style={{ padding: "15px", marginBottom: "75px" }}
            >
                <div>
                    <Link className="btn btn-primary" to="/orders/add">
                        Add Order
                    </Link>
                </div>

                <table className="table mt-3">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">File</th>
                            <th scope="col">Dealer</th>
                            <th scope="col">Request</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => (
                            <tr key={order.orderId}>
                                <th scope="row">
                                    {new Date(
                                        order.createdOn
                                    ).toLocaleDateString()}
                                </th>
                                <td>
                                    {order.fileName && (
                                        <div>
                                            <Paperclip />
                                            <a
                                                href="#"
                                                onClick={(e) =>
                                                    this.downloadFile(e, order)
                                                }
                                            >
                                                {order.fileName}
                                            </a>
                                        </div>
                                    )}
                                </td>
                                <td>{order.dealer.name}</td>
                                <td>
                                    {order.contact && (
                                        <Link
                                            to={{
                                                pathname: `/`,
                                                hash: `${order.contact.contactId.toString()}`,
                                            }}
                                        >
                                            {new Date(
                                                order.contact.createdOn
                                            ).toLocaleDateString()}{" "}
                                            (
                                            {
                                                order.contact
                                                    .contactProductGroups[0]
                                                    .productGroup.name
                                            }
                                            )
                                        </Link>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {orders.length === 0 && (
                    <div className="mt-1">
                        No orders uploaded. Click the button above to add your
                        first order
                    </div>
                )}
            </div>
        );
    }

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (
            this.renderOrders(this.state.orders)
        );

        return (
            <div>
                <h2>Orders</h2>
                {contents}
            </div>
        );
    }

    async populateOrderData() {
        try {
            const token = await authService.getAccessToken();

            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const response = await axios.get<Order[]>("api/orders", config);
            this.setState({ orders: response.data, loading: false });
        } catch (ex) {
            console.log(ex);
            //to do: create api wrapper and check for 401, try to refresh tokens etc.
            //window.location.href = "/authentication/logout";
        }
    }
}
