import React, { Component } from "react";

import { NavMenu } from "./NavMenu";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className="d-flex flex-column min-vh-100">
        <header>
          <NavMenu />
        </header>
        <main role="main" className="pt-3 flex-fill">
          <div className="container">{this.props.children}</div>
        </main>
        <footer className="pt-4">
          <div className="footer-legal">
            <div className="container text-center">
              <a
                href="https://www.deceuninck.com/en/terms-of-use.aspx"
                target="_blank"
              >
                Privacy policy
              </a>
              &nbsp;-&nbsp;
              <a
                href="https://www.deceuninck.com/en/cookie-statement.aspx"
                target="_blank"
              >
                Cookie statement
              </a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
